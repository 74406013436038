import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import { StaticImage } from 'gatsby-plugin-image';
import Seo from '../components/seo';

const Monika = () => {
    return (
        <Layout>
            <Seo title="Monika Adamiec"/>
            <section className="member-container">
                <section className="member-intro">
                    <div className="member-intro_text">
                        <h1 className="member-intro_text_name">Monika Adamiec</h1>
                        <p className="member-intro_text_description">Certyfikowana instruktorka Pilatesu, socjolożka, pedagożka specjalna
                        </p>
                    </div>
                    <div className="member-intro_picture-container">
                    <StaticImage 
                            src='../images/poza_monika.jpg' 
                            placeholder="blurred"
                            loading="eager"
                            alt="nstruktorka Monika Adamiec" 
                            className='member-intro_picture'
                        />
                    </div>
                </section>
                <section className="member-hero">
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">O MONICE</h2>
                        </div>
                        <div className="member-hero-paragraph_info">
                            <h3 className="member-hero-paragraph_info-bold">Certyfikowana instruktorka Pilatesu, socjolożka, pedagożka i pedagożka specjalna.</h3>
                            <br/>
                            <p className="member-hero-paragraph_info-text">Monika pracuje metodą Pilates w nurcie współczesnym, czyli sportowo- rehabilitacyjnym.</p>
                            <p className="member-hero-paragraph_info-text">Metoda Pilates ułatwiła jej powrót do sprawności po kilkunastoletniej kontuzji. To doświadczenie miało duży wpływ na jej podejście do pracy z klientami i klientkami. Największą satysfakcję dają jej osoby, którym metoda Pilates pomogła się otworzyć na ruch, nie bać się go i czerpać z niego radość.</p>
                            <p className="member-hero-paragraph_info-text">Lubi zarażać pasją do ruchu, pomagać w odkrywaniu swojego potencjału i możliwości swojego ciała, które zwykle potrafi więcej niż nam się wydaje.</p>
                            <p className="member-hero-paragraph_info-text">Ukończyła roczny specjalistyczny kurs dla instruktorów „Bio-Logika w Pilatesie - Pilates Teacher Excellence Program” obejmujący następujące zagadnienia: Diagnostyka w Pilatesie, Biomechanika w Pilatesie, Neurologia funkcjonalna w Pilatesie, Fizjologia oddechu w Pilatesie, Techniki dodatkowe w Pilatesie, Pedagogika w Pilatesie, Logika i motoryka w Pilatesie, Psychologia obsługi Klienta w Pilatesie (Alicja Rukowicz).</p>
                            <p className="member-hero-paragraph_info-text">Pozostałe kursy i szkolenia: Reformer Stage 1 (MK Pilates), Biomechanika w praktyce- warsztaty dla trenerów różnych metod pracy z ciałem (Zygmunt Bartosiewicz), Ćwiczenia Pilates w wadach postawy i schorzeniach kręgosłupa (Movimento Pilates), Kurs Instruktora Pilates 1 (Reha-fit).</p>
                            <p className="member-hero-paragraph_info-text">W wolnym czasie gra w gry planszowe, ćwiczy na siłowni, czyta książki i praktykuje uważność.</p>
                            <p className="member-hero-paragraph_info-text">Z wykształcenia socjolożka i pedagożka specjalna.</p>
                        </div>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">JĘZYKI<br/>LANGUAGES</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                            <li>Polski/ Polish</li>
                            <li>Angielski/ English</li>
                        </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">METODY</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                                <li>Pilates</li>
                                <li>Terapia ręki</li>
                            </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">RODZAJE ZAJĘĆ</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                                <li>Indywidualnie</li>
                                <li>W duetach</li>
                                <li>W minigrupach</li>
                            </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">RODZAJE SPRZĘTU</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                                <li>Reformer with Tower</li>
                                <li>Ladder Barrel</li>
                                <li>Combo Chair</li>
                                <li>Mata oraz sprzęt pilatesowy typu : Roller, Disc, Small Ball, Heavy Ball, Ovo Ball, Body Ball, Band, Magic Circle oraz dodatkowe akcesoria sportowe, np. małe ciężarki, piłeczki do automasażu, itp.</li>
                            </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">SPECJALIZACJE</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                                <li>Osoby z wadami postawy</li>
                                <li>Osoby potrzebujące rozwinąć świadomość i akceptację swojego ciała</li>
                                <li>Osoby aktywnie uprawiające różne sporty</li>
                            </ul>
                    </section>  
                    <section className="member-hero-paragraph">        
                            <div className="member-hero-paragraph_info_link-container">
                                <Link to="/zapisy" className="member-hero-paragraph_info_link-link">ZAPISZ SIĘ</Link>
                            </div>
                    </section>  
                </section>
            </section>
        </Layout>
    )
}

export default Monika;

// <img src={poza_monika} alt="instruktorka Monika Adamiec" className='member-intro_picture' />